/* ready */
$(function() {
  'use strict';

  /* 都道府県 */
  $('.mw_wp_form_confirm input[name=prefecture]').each(function() {
    if (!$(this).val()) {
      $(this).parents('td').text('');
    }
  });
});