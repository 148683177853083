/* ready */
$(function() {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function() {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  /* gnavi */
  $('.l-gnavi__list > li').hover(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 1024) {
      $(this).addClass('is-mnavi');
    }
  }, function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 1024) {
      $(this).removeClass('is-mnavi');
    }
  });

  /* sp-gnavi */
  $('.l-header__menu').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 1025) {
      if (!$(this).hasClass('is-active')) {
        $(this).addClass('is-active');
        $('.l-header__navi').stop().slideDown();
      } else {
        $(this).removeClass('is-active');
        $('.l-header__navi').stop().slideUp();
      }
    }
  });

  /* sp-mnavi */
  $('.l-gnavi__list__cat').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 1025) {
      if (!$(this).hasClass('is-open')) {
        $(this).addClass('is-open');
        $(this).next().stop().slideDown();
      } else {
        $(this).removeClass('is-open');
        $(this).next().stop().slideUp();
      }
    }
  });


  /* -- resize -- */
  $(window).resize(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 1024) {
      // reset
      $('.l-header__menu').removeClass('is-active');
      $('.l-header__navi').hide();
      $('.l-gnavi__list__cat').removeClass('is-open');
      $('.l-mnavi').hide();
      $('.l-mnavi').css('visibility','hidden');
    }
  });
});


/* -- load -- */
$(window).on('load' , function() {
  $('body').removeClass('is-preload');
});