/* ready */
$(function() {
  'use strict';

  /* スライダー */
  $('.p-top-mv__slide').slick({
    autoplay: true,
    arrows: false,
    dots: true,
    speed: 1000,
    autoplaySpeed: 3000
  }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var nextSlideItem = $('.slick-slide[data-slick-index="' + currentSlide + '"]').next();
    var prevSlideItem = $('.slick-slide[data-slick-index="' + (currentSlide) + '"]').prev();
    if (nextSlideItem.hasClass('slick-cloned')) {
      nextSlideItem.addClass('slick-now');
    }
    if (prevSlideItem.hasClass('slick-cloned')) {
      prevSlideItem.addClass('slick-now');
    }
  }).on('afterChange', function(event, slick, currentSlide, nextSlide){
    $('.slick-cloned').removeClass('slick-now');
  });
});