/* ready */
$(function() {
  'use strict';

  /* 活動状況 */
  var activitiesYear = $('.p-activities__archive__year');
  var activitiesSection = $('.p-activities > section');

  activitiesYear.click(function() {
    var activitiesIndex = activitiesYear.index(this);

    activitiesYear.parent().removeClass('is-current');
    $(this).parent().addClass('is-current');
    activitiesSection.hide();
    activitiesSection.eq(activitiesIndex).show();
  });
});